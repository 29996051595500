<template>
<div>
    <v-row align="center" class="my-3" v-if="step2 == false">
        <v-col cols="12" md="2">
            <v-btn @click="openAddDialog()" color="primary" large class=" btn">
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3>إضافة زائر</h3>
            </v-btn>
        </v-col>

    </v-row>
    <v-card outlined>

        <v-data-table :item-class="row_classes" class="mytable" :items-per-page="$global.state.filter.pageSize" :items="body.permitDatas" :loading="$global.state.loading" :headers="headers" hide-default-footer :loading-text="$t('loading')" :no-data-text="$t('no-data')" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">

            <template v-slot:item.actions="{item, index}">

                <v-tooltip bottom="" v-if="step2 == false">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteItem( index)" v-on="on" icon>
                            <v-icon color="error">
                                delete
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('hthf') }}</span>
                </v-tooltip>

                <v-tooltip bottom="" v-if="item.isReject == false && step2 == true">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="reject(index)" v-on="on" icon>
                            <v-icon color="error">
                                remove_circle
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>رفض</span>
                </v-tooltip>

                <v-tooltip bottom="" v-if="item.isReject == true">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="active(index)" v-on="on" icon>
                            <v-icon color="teal">
                                beenhere
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>قبول</span>
                </v-tooltip>

                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item, index)" v-on="on" icon>
                            <v-icon color="info">
                                edit
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('edit') }}</span>
                </v-tooltip>

            </template>
        </v-data-table>
        <v-col cols="12" md="6" v-if="$store.state.user.role == 'Security'">
            <h3 class="mb-2">ملاحظة سرية </h3>
            <v-text-field v-model="body.secretNote" placeholder="*********" filled outlined hide-details="auto" />
        </v-col>

        <v-col md="12" v-if="$store.state.user.role == 'Security'">
            <h4 class="mb-2">ملاحظات عامة </h4>
            <VueEditor dir="ltr" v-model="body.textEditor" />
        </v-col>

    </v-card>

    <v-card outlined class="mt-2" v-if="$store.state.user.role != 'Security'">
        <v-container fluid>
            <v-row class="ma-2">
                <v-btn v-show="body.permitDatas.length != 0" :disabled="body.permitDatas.length == 0" :loading="loading" dark large class="mx-2 btn" @click="postPermitData()" color="teal" style="border-radius: 0px; border: solid">
                    <h3>إرسال إلى قسم التدقيق الأمني</h3>
                </v-btn>
            </v-row>
        </v-container>
    </v-card>
    <v-card outlined class="mt-2" v-if="$store.state.user.role == 'Security'">
        <v-container fluid>
            <div class="mx-3 mb-5">
                <v-checkbox v-model="groupIds" color="orange" value="577bad80-a00a-404d-8081-ebecc80abe51" hide-details>
                    <template v-slot:label>
                        <h3>بوابة الB3</h3>
                    </template>
                </v-checkbox>
                <v-checkbox v-model="groupIds" color="indigo" value="f60b2a90-83d7-4fc4-a940-27f3d546b2a0" hide-details>
                    <template v-slot:label>
                        <h3>بوابة الموظفين</h3>
                    </template>
                </v-checkbox>
                <v-checkbox v-model="groupIds" color="red" value="d3af32a1-8237-45cc-b87e-b63fb88859a0" hide-details>
                    <template v-slot:label>
                        <h3>بوابة الشخصيات الهمة VIP</h3>
                    </template>
                </v-checkbox>
                <v-checkbox v-model="groupIds" color="indigo" value="a8e231a0-4f56-43ef-9011-c1cabe5a31bf" hide-details>
                    <template v-slot:label>
                        <h3>بوابة الموظفين الداخلية</h3>
                    </template>
                </v-checkbox>
                <v-checkbox v-model="groupIds" color="red" value="a18daf02-9c35-4887-9100-b249408fbc6f" hide-details>
                    <template v-slot:label>
                        <h3>بوابة الشخصيات المهمة الداخلية</h3>
                    </template>
                </v-checkbox>
            </div>
            <v-row class="ma-2">
                <v-btn v-show="body.permitDatas.length != 0" :disabled="body.permitDatas.length == 0" :loading="loading" dark large class="mx-2 btn" @click="postPermitData()" color="teal" style="border-radius: 0px; border: solid">
                    <h3>إرسال</h3>
                </v-btn>
            </v-row>
        </v-container>
    </v-card>
    <!-- <pre dir="ltr">
      {{this.body.permitDatas}}
    </pre> -->
    <Dialog />
</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
export default {
    components: {
        Dialog: () => import('./dialog'),
        VueEditor
    },
    data() {
        return {
            step2: false,
            items: [],
            groupIds: [],
            loading: false,
            body: {
                "sequence": this.$store.state.user.role == 'Security' ? 2 : 1,
                "permitId": this.$route.params.permitId,
                "textEditor": "",
                "secretNote": "",
                "userId": this.$store.state.user.id,
                "permitStepGroups": [],
                "permitDatas": []
            },
            headers: [{
                    text: 'الإسم الثلاثي للزائر',
                    value: 'fullName'
                },
                {
                    text: 'الصفة',
                    value: 'jobTitle'
                },
                {
                    text: 'الجهة الزائرة',
                    value: 'visiting'
                },
                {
                    text: 'الأجهزة',
                    value: 'devices'
                },
                {
                    text: 'نوع العجلة',
                    value: 'carName'
                },
                {
                    text: 'لون العجلة',
                    value: 'carColor'
                },
                {
                    text: 'موديل العجلة',
                    value: 'carYear'
                },
                {
                    text: 'نوع التخويل',
                    value: 'type'
                },
                {
                    text: 'ملاحظة',
                    value: 'note'
                },
                {
                    text: '',
                    value: 'actions'
                }
            ]
        }
    },

    mounted() {
        this.getItems()

        this.$eventBus.$on(`save-item`, (item) => {
            console.log(item)
            this.body.permitDatas.push(item)
        })

        this.$eventBus.$on(`edit-item`, (item) => {
            console.log(item)
            this.deleteItem(item.index)
            this.body.permitDatas.push(item)
        })

    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`)
    },

    methods: {
        row_classes(item) {
            if (item.isReject == true) {
                return "pink darken-3 white--text"; //can also return multiple classes e.g ["orange","disabled"]
            }
        },
        reject(index) {
            this.body.permitDatas[index].isReject = true
        },
        active(index) {
            this.body.permitDatas[index].isReject = false
        },

        async getItems() {
            this.$global.state.loading = true
            try {
                var res = await this.$http.get(`PermitData?PermitStepId=${this.$route.params.permitStepId}`)
                console.log(res)
                this.body.permitDatas = res.data.result
                if (this.body.permitDatas.length > 0) {
                    this.step2 = true
                }
                for (let i = 0; i < this.body.permitDatas.length; i++) {
                    this.body.permitDatas[i].type = this.body.permitDatas[i].type.split(',')
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$global.state.loading = false
            }
        },

        async postPermitData() {
            console.log('this.body.permitDatas.map(x => x.type)')
            console.log(this.body.permitDatas.filter(x => x.type).toString())
            console.log(this.body.permitDatas)
            this.body.permitDatas.filter(x => x.type).toString()
            for (let i = 0; i < this.body.permitDatas.length; i++) {
                this.body.permitDatas[i].type = this.body.permitDatas[i].type.toString()
            }

            if (this.$store.state.user.role == 'Benefit') {
                this.body.permitStepGroups = [{
                    "groupId": "d417eb4d-2acd-4622-adc4-6c9673c06924" //قسم التدقيق الأمني
                }]
            }
            if (this.$store.state.user.role == 'Security') {
                this.permitStepGroups = []
                for (let i = 0; i < this.groupIds.length; i++) {
                    this.body.permitStepGroups.push({
                        "groupId": this.groupIds[i]
                    })
                }
            }

            this.loading = true

            try {
                await this.$http.put(`PermitStep/${this.$route.params.permitStepId}`, this.body)
                this.$service.success()
                this.$router.push(`/permit`)
            } catch (e) {
                this.$service.faild()
                console.log(e)
            } finally {
                this.loading = false
            }
        },

        deleteItem(i) {
            this.body.permitDatas.splice(i, 1)
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
        },

        openEditDialog(item, index) {
            item.dialogType = 'edit'
            item.index = index
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fill-fields')
        },
    },
}
</script>

<style>
.red {
    background-color: rgba(242, 85, 85, 0.436);
}
</style>
